
  @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: black;
  font-family: 'Poppins', sans-serif;
}
body{
  background: repeating-linear-gradient(to right, red, red 50px, rgb(104, 0, 0) 50px, rgb(104, 0, 0) 100px);
  background-size: 150% 150%;
}
.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;

}
.quote-container {
  background: white;
  width: 95vw;
  box-shadow: 10px 10px rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.quote {
  text-transform: uppercase;
  transition: all 0.2s ease;
  font-size: 3em;
}
.quote:hover {
  color: red;
}

.btn {
  background: white;
  border: 1px solid black;
  padding: 1rem 2rem;
  font-size: 2rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0px 0px black;
  transform: translate(0px,0px);
}
.btn:first-of-type{
  margin-top: 5rem;
}
.btn:nth-of-type(2){
  margin-top: 2rem;
}
.btn:is(:hover){
  box-shadow: 5px 5px black;
}
.btn:is(:active){
  box-shadow: 0px 0px black;
  transform: translate(0px,0px);
}
.footer{
  display: flex;
  gap: 2rem;
}
.socials{
  color: white;
  transition: all 0.2s ease;
  
}
.socials:hover{
  color: rgb(0, 255, 242);
}
.btns{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}